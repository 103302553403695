<template>
	<div class="mdocksFormHeader puiformheader"></div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mdocks-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mdocks'
		};
	},
	computed: {}
};
</script>
